import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-bebas-neue" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.emailVerified)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Email Send Verified Email "))
      : _createCommentVNode("", true)
  ]))
}