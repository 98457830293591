import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { flex: "flex flex-col" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessions, (session) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "text-[1.5vw] border border-white flex flex-row justify-between mb-5",
          key: session.sessionId
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.divListClass])
            }, [
              _createElementVNode("p", {
                class: _normalizeClass([_ctx.pListClass])
              }, " Name: ", 2),
              _createElementVNode("p", {
                class: _normalizeClass([_ctx.pListClass, 'mx-2'])
              }, _toDisplayString(session.name), 3),
              _createElementVNode("p", {
                class: _normalizeClass([_ctx.pListClass])
              }, " Date: ", 2),
              _createElementVNode("p", {
                class: _normalizeClass([_ctx.pListClass, 'mx-2'])
              }, _toDisplayString(session.date), 3)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.divListClass])
            }, [
              _createElementVNode("p", {
                class: _normalizeClass([_ctx.pListClass])
              }, " Session ID: ", 2),
              _createElementVNode("p", {
                class: _normalizeClass([_ctx.pListClass])
              }, _toDisplayString(session.sessionId), 3)
            ], 2)
          ]),
          _createElementVNode("button", {
            class: "text-button-text-color mr-4",
            onClick: ($event: any) => (_ctx.handleViewMoreClick(session))
          }, " View More ", 8, _hoisted_2)
        ]))
      }), 128))
    ])
  ]))
}