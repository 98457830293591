import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row ml-10 justify-top font-bebas-neue w-full" }
const _hoisted_2 = { class: "flex flex-row justify-between w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SessionsHouseListComponent = _resolveComponent("SessionsHouseListComponent")!
  const _component_HouseStatsComponent = _resolveComponent("HouseStatsComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.divClass, 'w-1/3'])
      }, [
        _createElementVNode("p", {
          class: _normalizeClass([_ctx.titleClass])
        }, " Recent Sessions ", 2),
        _createVNode(_component_SessionsHouseListComponent, { onSessions: _ctx.sessions }, null, 8, ["onSessions"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.divClass, 'w-2/3'])
      }, [
        _createElementVNode("p", {
          class: _normalizeClass([_ctx.titleClass])
        }, " House Stats ", 2),
        _createVNode(_component_HouseStatsComponent)
      ], 2)
    ])
  ]))
}