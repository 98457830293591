import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col ml-10 justify-top font-bebas-neue w-full" }
const _hoisted_2 = { class: "flex flex-row justify-between mt-3" }
const _hoisted_3 = { class: "w-full flex flex-row h-full" }
const _hoisted_4 = { class: "flex flex-col w-1/4 mr-[5vw]" }
const _hoisted_5 = { class: "overflow-y-auto flex-grow" }
const _hoisted_6 = { class: "text-[2vw]" }
const _hoisted_7 = { class: "text-[2vw]" }
const _hoisted_8 = { class: "text-red-500 text-[2vw]" }
const _hoisted_9 = { class: "w-2/5 flex flex-col" }
const _hoisted_10 = { class: "text-red-500 text-[2vw]" }
const _hoisted_11 = { class: "overflow-y-auto scrollbar-hide flex-grow" }
const _hoisted_12 = { class: "w-full border-collapse leading-normal" }
const _hoisted_13 = ["onUpdate:modelValue"]
const _hoisted_14 = { class: "text-red-500" }
const _hoisted_15 = ["onUpdate:modelValue"]
const _hoisted_16 = ["onUpdate:modelValue"]
const _hoisted_17 = ["onUpdate:modelValue"]
const _hoisted_18 = ["onUpdate:modelValue"]
const _hoisted_19 = { class: "flex flex-row justify-evenly" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignOutBarComponent = _resolveComponent("SignOutBarComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "text-[2.5vw] ml-4 text-button-text-color",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args)))
      }, " back "),
      _createVNode(_component_SignOutBarComponent, {
        class: "mr-7",
        onIsLoggedIn: _ctx.handleLoggedInChange
      }, null, 8, ["onIsLoggedIn"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.infoDivClass])
          }, [
            _createElementVNode("label", {
              class: _normalizeClass([_ctx.infoLabelClass]),
              for: "name"
            }, "Name: ", 2),
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass([_ctx.infoInputClass]),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.session.name) = $event)),
              type: "text",
              placeholder: "session name"
            }, null, 2), [
              [_vModelText, _ctx.session.name]
            ])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.infoDivClass])
          }, [
            _createElementVNode("label", {
              class: _normalizeClass([_ctx.infoLabelClass]),
              for: "date"
            }, "Date: ", 2),
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass([_ctx.infoInputClass]),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.session.date) = $event)),
              type: "date"
            }, null, 2), [
              [_vModelText, _ctx.session.date]
            ])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.infoDivClass])
          }, [
            _createElementVNode("label", {
              class: _normalizeClass([_ctx.infoLabelClass]),
              for: "bigBlind"
            }, "Big Blind (BB): ", 2),
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass([_ctx.infoInputClass]),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.session.bigBlind) = $event)),
              type: "number"
            }, null, 2), [
              [_vModelText, _ctx.session.bigBlind]
            ])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.infoDivClass])
          }, [
            _createElementVNode("label", {
              class: _normalizeClass([_ctx.infoLabelClass]),
              for: "smallBlind"
            }, "Small Blind (SB): ", 2),
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass([_ctx.infoInputClass]),
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.session.smallBlind) = $event)),
              type: "number"
            }, null, 2), [
              [_vModelText, _ctx.session.smallBlind]
            ])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.infoDivClass])
          }, [
            _createElementVNode("label", {
              class: _normalizeClass([_ctx.infoLabelClass]),
              for: "totalBuyIn"
            }, "Total Buy In: ", 2),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.session.totalBuyIn), 1)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.infoDivClass])
          }, [
            _createElementVNode("label", {
              class: _normalizeClass([_ctx.infoLabelClass]),
              for: "totalBuyOut"
            }, "Total Buy Out: ", 2),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.session.totalBuyOut), 1)
          ], 2),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.buyInErrorMessage), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.errorMessage), 1),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("table", _hoisted_12, [
            _cache[8] || (_cache[8] = _createElementVNode("thead", { class: "text-[2vw] sticky top-0" }, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", { class: "w-1/3 text-left pb-3" }, "Player"),
                _createElementVNode("th", { class: "w-1/6 text-left pb-3" }, "In"),
                _createElementVNode("th", { class: "w-1/4 text-left pb-3" }, "Out"),
                _createElementVNode("th", { class: "w-1/12 text-left pb-3" }, "UID"),
                _createElementVNode("th", { class: "w-1/6 text-left pb-3" })
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.session.players, (player, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", {
                    class: _normalizeClass([_ctx.tdInputClass])
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": ($event: any) => ((player.username) = $event),
                      class: _normalizeClass([_ctx.playerInfoThClass]),
                      placeholder: "Player Name",
                      type: "text"
                    }, null, 10, _hoisted_13), [
                      [_vModelText, player.username]
                    ]),
                    _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.playerErrorMessages[index]), 1)
                  ], 2),
                  _createElementVNode("td", {
                    class: _normalizeClass([_ctx.tdInputClass])
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": ($event: any) => ((player.buyIn) = $event),
                      class: _normalizeClass([_ctx.playerInfoThClass]),
                      placeholder: "In",
                      type: "number"
                    }, null, 10, _hoisted_15), [
                      [
                        _vModelText,
                        player.buyIn,
                        void 0,
                        { number: true }
                      ]
                    ])
                  ], 2),
                  _createElementVNode("td", {
                    class: _normalizeClass([_ctx.tdInputClass])
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": ($event: any) => ((player.buyOut) = $event),
                      class: _normalizeClass([_ctx.playerInfoThClass]),
                      placeholder: "Out",
                      type: "number"
                    }, null, 10, _hoisted_16), [
                      [
                        _vModelText,
                        player.buyOut,
                        void 0,
                        { number: true }
                      ]
                    ])
                  ], 2),
                  _createElementVNode("td", {
                    class: _normalizeClass([_ctx.tdInputClass])
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": ($event: any) => ((player.member) = $event),
                      class: _normalizeClass([_ctx.playerInfoThClass]),
                      placeholder: "Member",
                      type: "checkbox"
                    }, null, 10, _hoisted_17), [
                      [_vModelCheckbox, player.member]
                    ])
                  ], 2),
                  _createElementVNode("td", {
                    class: _normalizeClass([_ctx.tdInputClass])
                  }, [
                    (player.member)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 0,
                          "onUpdate:modelValue": ($event: any) => ((player.uid) = $event),
                          class: _normalizeClass([_ctx.playerInfoThClass]),
                          placeholder: "UUID",
                          type: "string"
                        }, null, 10, _hoisted_18)), [
                          [_vModelText, player.uid]
                        ])
                      : _createCommentVNode("", true)
                  ], 2)
                ]))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.removePlayerClick && _ctx.removePlayerClick(...args))),
            class: "mt-2 text-[1.5vw] text-button-text-color"
          }, " Remove Player "),
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addPlayerClick && _ctx.addPlayerClick(...args))),
            class: "mt-2 text-[1.5vw] text-button-text-color"
          }, " Add Player "),
          _createElementVNode("button", {
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.submitClick && _ctx.submitClick(...args))),
            class: "mt-2 text-[1.5vw] text-button-text-color"
          }, " SUBMIT ")
        ])
      ])
    ])
  ]))
}