import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex text-[3vh] w-full h-full" }
const _hoisted_2 = {
  key: 0,
  class: "w-full h-full"
}
const _hoisted_3 = { class: "table-auto w-full" }
const _hoisted_4 = { class: "text-[2vw]" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-row justify-evenly text-button-text-color"
}
const _hoisted_6 = {
  key: 1,
  class: "w-full h-full flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.session)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, "session id: " + _toDisplayString(_ctx.session.sessionId), 1),
          _createElementVNode("p", null, "session name: " + _toDisplayString(_ctx.session.name), 1),
          _createElementVNode("p", null, "session date: " + _toDisplayString(_ctx.session.date), 1),
          _createElementVNode("table", _hoisted_3, [
            _createElementVNode("thead", _hoisted_4, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  class: _normalizeClass([_ctx.thClass])
                }, "Player", 2),
                _createElementVNode("th", {
                  class: _normalizeClass([_ctx.thClass])
                }, "In", 2),
                _createElementVNode("th", {
                  class: _normalizeClass([_ctx.thClass])
                }, "Out", 2),
                _createElementVNode("th", {
                  class: _normalizeClass([_ctx.thClass])
                }, "Profit", 2)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.session.players, (player, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, _toDisplayString(player.username), 1),
                  _createElementVNode("td", null, _toDisplayString(player.buyIn), 1),
                  _createElementVNode("td", null, _toDisplayString(player.buyOut), 1),
                  _createElementVNode("td", null, _toDisplayString(player.buyOut - player.buyIn), 1)
                ]))
              }), 128))
            ])
          ]),
          (_ctx.isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: _normalizeClass([_ctx.buttonClass, 'px-4 py-1.5']),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleEditClick && _ctx.handleEditClick(...args)))
                }, "EDIT", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([_ctx.buttonClass, 'px-3 py-2']),
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleViewMoreClick && _ctx.handleViewMoreClick(...args)))
                }, "VIEW MORE", 2)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
          _createElementVNode("p", { class: "text-[4vw] font-bold" }, "PICK A SESSION", -1)
        ])))
  ]))
}