import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-bebas-neue ml-5" }
const _hoisted_2 = { class: "flex flex-row justify-between mt-3" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row justify-evenly"
}
const _hoisted_4 = { class: "text-[2vw] w-1/4" }
const _hoisted_5 = { class: "table-auto w-1/4" }
const _hoisted_6 = { class: "text-[2vw]" }
const _hoisted_7 = { class: "text-[1.5vw]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignOutBarComponent = _resolveComponent("SignOutBarComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "text-[2.5vw] text-button-text-color",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args)))
      }, " back "),
      _createVNode(_component_SignOutBarComponent, {
        class: "mr-7",
        onIsLoggedIn: _ctx.handleLoggedInChange
      }, null, 8, ["onIsLoggedIn"])
    ]),
    (_ctx.session)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", null, "session id: " + _toDisplayString(_ctx.session.sessionId), 1),
            _createElementVNode("p", null, "session name: " + _toDisplayString(_ctx.session.name), 1),
            _createElementVNode("p", null, "session date: " + _toDisplayString(_ctx.session.date), 1)
          ]),
          _createElementVNode("table", _hoisted_5, [
            _createElementVNode("thead", _hoisted_6, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  class: _normalizeClass([_ctx.thClass])
                }, "Player", 2),
                _createElementVNode("th", {
                  class: _normalizeClass([_ctx.thClass])
                }, "In", 2),
                _createElementVNode("th", {
                  class: _normalizeClass([_ctx.thClass])
                }, "Out", 2),
                _createElementVNode("th", {
                  class: _normalizeClass([_ctx.thClass])
                }, "Profit", 2)
              ])
            ]),
            _createElementVNode("tbody", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.session.players, (player, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, _toDisplayString(player.username), 1),
                  _createElementVNode("td", null, _toDisplayString(player.buyIn), 1),
                  _createElementVNode("td", null, _toDisplayString(player.buyOut), 1),
                  _createElementVNode("td", null, _toDisplayString(player.buyOut - player.buyIn), 1)
                ]))
              }), 128))
            ])
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-1/2" }, null, -1))
        ]))
      : _createCommentVNode("", true)
  ]))
}