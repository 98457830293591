import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-bebas-neue" }
const _hoisted_2 = { class: "text-[2vw] text-button-text-color" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoggedIn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.isLoggedIn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_router_link, { to: "/account/info" }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Account ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = () => {_ctx.signOutClick()})
                  }, " Sign Out ")
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, " Not logged in "))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = () => {_ctx.accountClick('sign-up')}),
              class: "mr-4"
            }, "SIGN UP"),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = () => {_ctx.accountClick('login')})
            }, "LOGIN")
          ]))
    ])
  ]))
}