import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-6xl font-['Bebas_Neue'] mb-4" }, "404 Not Found", -1)),
    _createVNode(_component_router_link, {
      to: "/",
      class: "text-[2vw] font-['Bebas_Neue'] px-3 py-2 text-button-text-color hover:border-4 hover:border-white transition-all duration-300"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Go to Home ")
      ])),
      _: 1
    })
  ]))
}