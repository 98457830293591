
<template>
    <div class="flex justify-start
        bg-main-primary-color min-w-screen
        min-h-screen
        text-main-primary-font-color
        ">
        <router-view class="w-full h-full"></router-view>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>

<style>
</style>
