import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col font-bebas-neue" }
const _hoisted_2 = { class: "flex flex-row justify-between mr-4 mt-3" }
const _hoisted_3 = { class: "flex flex-row justify-between text-[2.5vw] mx-[4vw]" }
const _hoisted_4 = { class: "flex flex-row justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignOutBarComponent = _resolveComponent("SignOutBarComponent")!
  const _component_SessionsPlayedListComponent = _resolveComponent("SessionsPlayedListComponent")!
  const _component_HostedHouseListComponent = _resolveComponent("HostedHouseListComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "text-[2.5vw] ml-6 text-button-text-color",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args)))
      }, " back "),
      _createVNode(_component_SignOutBarComponent)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.containerDivClass])
      }, [
        _cache[4] || (_cache[4] = _createElementVNode("p", null, " Account Info ", -1)),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " Username: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.currUserPlayer?.username), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " Email: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.currUserPlayer?.email), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " # Hosted House Games: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.currUserPlayer?.houseGamesHosted.length), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " # Hosted Sessions: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.currUserPlayer?.sessionsHosted.length), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " # Games Played: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.currUserPlayer?.sessionsPlayedIds.length), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " # Host Games Played: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.currUserPlayer?.houseGamesPlayedIds.length), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " Total Profit: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.currUserPlayer?.buyOut - _ctx.currUserPlayer?.buyIn), 3)
        ], 2),
        _createElementVNode("button", {
          class: "text-[2vw] px-2 py-3 text-button-text-color button-custom-hover-class",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleAccEdit && _ctx.handleAccEdit(...args)))
        }, " Edit Account ")
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.containerDivClass])
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("p", null, " Recent Sessions ", -1)),
        _createVNode(_component_SessionsPlayedListComponent, { currUser: _ctx.currUserPlayer }, null, 8, ["currUser"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.containerDivClass])
      }, [
        _createElementVNode("div", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("p", null, " Home Games ", -1)),
          _createElementVNode("button", {
            class: "text-button-text-color text-[1.5vw]",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleHomeClick && _ctx.handleHomeClick(...args)))
          }, "Search public house")
        ]),
        _createVNode(_component_HostedHouseListComponent, { currUser: _ctx.currUserPlayer }, null, 8, ["currUser"]),
        _createElementVNode("button", {
          class: "text-button-text-color text-[2vw] button-custom-hover-class px-2 py-3 mt-5",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.createHouseClick && _ctx.createHouseClick(...args)))
        }, " Create House ")
      ], 2)
    ])
  ]))
}