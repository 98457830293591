import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col font-bebas-neue ml-5" }
const _hoisted_2 = { class: "flex flex-row justify-between mt-3" }
const _hoisted_3 = { class: "text-[2.5vw] flex flex-col items-center" }
const _hoisted_4 = { class: "flex flex-row justify-evenly w-full text-button-text-color mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignOutBarComponent = _resolveComponent("SignOutBarComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-[2.5vw]" }, " New House Game ", -1)),
      _createVNode(_component_SignOutBarComponent, { class: "mr-7" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.divInputClass])
      }, [
        _cache[6] || (_cache[6] = _createElementVNode("label", null, " Name: ", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newHouse.name) = $event)),
          type: "text",
          class: _normalizeClass([_ctx.inputClass])
        }, null, 2), [
          [_vModelText, _ctx.newHouse.name]
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.divInputClass])
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("label", null, " Location: ", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newHouse.locationValue) = $event)),
          type: "text",
          class: _normalizeClass([_ctx.inputClass])
        }, null, 2), [
          [_vModelText, _ctx.newHouse.locationValue]
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.divInputClass])
      }, [
        _cache[8] || (_cache[8] = _createElementVNode("label", null, " Discription: ", -1)),
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newHouse.discription) = $event)),
          type: "text",
          class: _normalizeClass([_ctx.inputClass, 'text-[1.5vw] w-full h-40 max-h-60 min-h-40 overflow-hidden resize-y'])
        }, null, 2), [
          [_vModelText, _ctx.newHouse.discription]
        ])
      ], 2),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "button-custom-hover-class px-7 py-1.5",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args)))
        }, " Back "),
        _createElementVNode("button", {
          class: "button-custom-hover-class px-7 py-1.5",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleSubmitClick && _ctx.handleSubmitClick(...args)))
        }, " Submit ")
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.errMessage), 1)
    ])
  ]))
}