import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-top font-bebas-neue w-full" }
const _hoisted_2 = { class: "w-full table-auto" }
const _hoisted_3 = { class: "text-[2vw] w-full" }
const _hoisted_4 = { class: "text-[1.5vw] w-full" }
const _hoisted_5 = { class: "text-[#FCF0CC]" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", _hoisted_3, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", {
            class: _normalizeClass([_ctx.thClass])
          }, "Date", 2),
          _createElementVNode("th", {
            class: _normalizeClass([_ctx.thClass])
          }, "Name", 2),
          _createElementVNode("th", {
            class: _normalizeClass([_ctx.thClass])
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("button", null, " View ", -1)
          ]), 2)
        ])
      ]),
      _createElementVNode("tbody", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sessions, (session) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: session.sessionId
          }, [
            _createElementVNode("td", null, _toDisplayString(session.date), 1),
            _createElementVNode("td", null, _toDisplayString(session.name), 1),
            _createElementVNode("td", _hoisted_5, [
              _createElementVNode("button", {
                onClick: () => _ctx.viewMoreSessionClick(session)
              }, "VIEW MORE", 8, _hoisted_6)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}