import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center w-full h-screen" }
const _hoisted_2 = { class: "bg-[#494949] p-8 rounded-lg shadow-2xl" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = { class: "text-[1vw] text-red-600" }
const _hoisted_5 = { class: "mt-2 flex flex-row w-full justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-2xl font-bold mb-6 text-gray-300 text-center" }, "Sign In", -1)),
      _createElementVNode("form", {
        class: "space-y-4",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", {
            class: _normalizeClass([_ctx.labelClass])
          }, " Email: ", 2),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass([_ctx.inputClass]),
            placeholder: "example@gmail.com",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            type: "text"
          }, null, 2), [
            [_vModelText, _ctx.email]
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("label", {
            class: _normalizeClass([_ctx.labelClass])
          }, " Password: ", 2),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass([_ctx.inputClass]),
            placeholder: "password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
            type: "password"
          }, null, 2), [
            [_vModelText, _ctx.password]
          ])
        ]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = () => {_ctx.loginInClick()}),
          class: "w-full bg-transparent border border-white text-white py-2 rounded-md hover:bg-button-text-color hover:text-black transition duration-300"
        }, " Login ")
      ], 32),
      _createElementVNode("div", _hoisted_5, [
        _cache[5] || (_cache[5] = _createElementVNode("p", null, " Need an account? ", -1)),
        _createElementVNode("button", {
          onClick: _cache[4] || (_cache[4] = () => {_ctx.signUpClick()}),
          class: "text-button-text-color"
        }, " Sign Up ")
      ])
    ])
  ]))
}