import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginComponent = _resolveComponent("LoginComponent")!
  const _component_SignUpComponent = _resolveComponent("SignUpComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.accountActionType === 'login')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LoginComponent, {
            onTypeChange: _ctx.handleAccChange,
            pageRouteName: _ctx.pageRouteName
          }, null, 8, ["onTypeChange", "pageRouteName"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SignUpComponent, {
            onTypeChange: _ctx.handleAccChange,
            pageRouteName: _ctx.pageRouteName
          }, null, 8, ["onTypeChange", "pageRouteName"])
        ]))
  ]))
}