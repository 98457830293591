import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-evenly w-full h-screen font-bebas-neue p-6" }
const _hoisted_2 = { class: "flex flex-row justify-between items-start w-full" }
const _hoisted_3 = { class: "text-[2vw] text-button-text-color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignOutBarComponent = _resolveComponent("SignOutBarComponent")!
  const _component_InputComponent = _resolveComponent("InputComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text-[13vw] leading-none mt-[-0.1em]" }, "POKER FOR THE POOR", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SignOutBarComponent, { onIsLoggedIn: _ctx.handleLoggedInChnage }, null, 8, ["onIsLoggedIn"])
      ])
    ]),
    _createVNode(_component_InputComponent)
  ]))
}