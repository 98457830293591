import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-bebas-neue flex flex-col" }
const _hoisted_2 = { class: "flex flex-row justify-between mt-3" }
const _hoisted_3 = { class: "flex flex-row" }
const _hoisted_4 = { class: "flex flex-row justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignOutBarComponent = _resolveComponent("SignOutBarComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "text-[2vw] ml-6 text-button-text-color",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBackClick && _ctx.handleBackClick(...args)))
      }, " back "),
      _createVNode(_component_SignOutBarComponent, {
        class: "mr-7",
        onIsLoggedIn: _ctx.handleLoggedInChange
      }, null, 8, ["onIsLoggedIn"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.containerDivClass])
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-[2vw]" }, " Account Info ", -1)),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " Username: ", 2),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.username) = $event)),
            class: _normalizeClass([_ctx.inputClass]),
            placeholder: "username",
            type: "text"
          }, null, 2), [
            [_vModelText, _ctx.user.username]
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " Email: ", 2),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.email) = $event)),
            class: _normalizeClass([_ctx.inputClass]),
            placeholder: "email",
            type: "text"
          }, null, 2), [
            [_vModelText, _ctx.user.email]
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " UID: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.user.uid), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " # Hosted House Games: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.user.houseGamesHosted.length), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " # Hosted Sessions: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.user.sessionsHosted.length), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " # Games Played: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.user.sessionsPlayedIds.length), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " # Host Games Played: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.user.houseGamesPlayedIds.length), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.divClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, " Total Profit: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.pClass])
          }, _toDisplayString(_ctx.user.buyOut - _ctx.user.buyIn), 3)
        ], 2),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "text-[2vw] text-button-text-color px-6 py-3 button-custom-hover-class mt-3",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleSubmitClick && _ctx.handleSubmitClick(...args)))
          }, " Submit "),
          _createElementVNode("button", {
            class: "text-[2vw] text-button-text-color px-6 py-3 button-custom-hover-class mt-3",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleResetClick && _ctx.handleResetClick(...args)))
          }, " Reset ")
        ])
      ], 2),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex flex-col w-1/3" }, [
        _createElementVNode("div", { class: "flex flex-row text-[2vw] justify-between" }, [
          _createElementVNode("p", { class: "" }, " Graphics ")
        ])
      ], -1))
    ])
  ]))
}