import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col ml-10 justify-top font-bebas-neue w-full" }
const _hoisted_2 = { class: "flex mt-4 flex-row justify-between" }
const _hoisted_3 = { class: "text-[2vw]" }
const _hoisted_4 = { class: "flex flex-row" }
const _hoisted_5 = { class: "w-1/4" }
const _hoisted_6 = { class: "w-1/4" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "w-2/4 h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SignOutBarComponent = _resolveComponent("SignOutBarComponent")!
  const _component_SessionsHouseListComponent = _resolveComponent("SessionsHouseListComponent")!
  const _component_SessionDetailComponent = _resolveComponent("SessionDetailComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, " House Id: " + _toDisplayString(_ctx.houseId), 1),
      _createVNode(_component_SignOutBarComponent, {
        class: "mr-7",
        onIsLoggedIn: _ctx.handleLoggedInChange
      }, null, 8, ["onIsLoggedIn"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-[4vw]" }, " House Info ", -1)),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.houseDivClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.housePClass])
          }, " Name: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.housePClass])
          }, _toDisplayString(_ctx.currentHouse?.name), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.houseDivClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.housePClass])
          }, " Location: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.housePClass])
          }, _toDisplayString(_ctx.currentHouse?.locationValue), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.houseDivClass])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.housePClass])
          }, " Discription: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.housePClass])
          }, _toDisplayString(_ctx.currentHouse?.discription), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.houseDivClass, 'flex-col'])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.housePClass])
          }, " Host UID: ", 2),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.housePClass])
          }, _toDisplayString(_ctx.currentHouse?.hostUid), 3)
        ], 2),
        _createElementVNode("button", {
          class: "text-[2vw] text-button-text-color",
          onClick: _cache[0] || (_cache[0] = () => _ctx.viewMoreHouseClick())
        }, "VIEW MORE")
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-[4vw]" }, " Sessions ", -1)),
          (_ctx.isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onCreateClick && _ctx.onCreateClick(...args))),
                  class: "text-[2vw] text-[#FCF0CC]"
                }, " Create New Session ")
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_SessionsHouseListComponent, {
            sessions: _ctx.sessions,
            onSelectedSession: _ctx.viewMoreSessionClick
          }, null, 8, ["sessions", "onSelectedSession"])
        ])
      ]),
      (!_ctx.newSessionFlag)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_SessionDetailComponent, {
              session: _ctx.selectedSession,
              isLoggedIn: _ctx.isLoggedIn
            }, null, 8, ["session", "isLoggedIn"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}