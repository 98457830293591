import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.houses, (house) => {
        return (_openBlock(), _createElementBlock("li", {
          key: house.houseId,
          class: _normalizeClass([_ctx.liClass])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.liDivClass])
            }, [
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "mx-3" }, " House Name: ", -1)),
              _createElementVNode("p", null, _toDisplayString(house.name), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.liDivClass])
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mx-3" }, " House Id: ", -1)),
              _createElementVNode("p", null, _toDisplayString(house.houseId), 1)
            ], 2)
          ]),
          _createElementVNode("button", {
            class: "mr-6 text-button-text-color",
            onClick: ($event: any) => (_ctx.handleViewMoreClick(house))
          }, " VIEW MORE ", 8, _hoisted_3)
        ], 2))
      }), 128))
    ])
  ]))
}